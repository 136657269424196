<template>
    <div>
        <section class="login">
            <div class="half_inner height_half_inner">
                <header class="section_header header_flex">
                    <h2 class="text_xxl text_white text_300">Recuperar contraseña</h2>
                    <div class="breadcrumbs">
                        <ul>
                            <li>
                                <router-link to="/" class="breadcrumbs_item">Home<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg></router-link>
                            </li>
                            <li>
                                <router-link to="/Login" class="breadcrumbs_item">Login<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg></router-link>
                            </li>
                            <li>
                                <span class="breadcrumbs_item">Recuperar Contraseña</span>
                            </li>
                        </ul>
                    </div>
                </header>
                <div v-if="!slug" class="content_block padded rounded white">
                    <div class="login_block grid grid_pad flex_space">
                        <div class="grid_col grid_col_1_2">
                            <div class="grid_col_box">
                                <h2 class="text_l mb30">Para recuperar tu contraseña introduce tu email en este campo.</h2>
                            </div>
                        </div>
                        <div class="grid_col grid_col_1_2">
                            <div class="grid_col_box">
                                <form class="form" @submit.prevent="recuperar">
                                    <div class="input_block">
                                        <input type="email" v-model="email" placeholder="Email" required/>
                                    </div>
                                    <div class="input_block">
                                        <button type="text">Enviar</button>
                                    </div>
                                    <div class="message" :class="msg.type" v-if="msg">
                                        <h3>{{msg.text}}</h3>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="slug" class="content_block padded rounded white">
                    <div class="grid grid_pad flex_space">
                        <div class="grid_col grid_col_1_2">
                            <div class="grid_col_box">
                                <h2 class="text_l mb30">A continuación, intruduce tu nueva contraseña</h2>
                            </div>
                        </div>
                        <div class="grid_col grid_col_1_2">
                            <div class="grid_col_box">
                                <form class="form" @submit.prevent="changePassword">
                                    <div class="input_block">
                                        <input v-model="p1" type="password" placeholder="Contraseña" required/>
                                    </div>
                                    <div class="input_block">
                                        <input v-model="p2" type="password" placeholder="Repite Contraseña" required>
                                    </div>
                                    <div class="input_block">
                                        <button type="submit">Enviar</button>
                                    </div>
                                    <div class="input_block">
                                        <div class="legal_line">
                                            <p class="legal">Todos los campos son imprescindibles</p>
                                        </div>
                                    </div>
                                    <div class="message" :class="msg.type" v-if="msg">
                                        <h3 v-if="msg.type == 'ok'">Contraseña cambiada con éxito. Ir al <router-link to="login">Login</router-link></h3>
                                        <h3 v-else>{{msg.text}}</h3>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Recuperar',
    data: function(){
        return{
            slug: null,
            email: null,
            msg: null,
            p1: null,
            p2: null
        }
    },
    created: function(){
        this.slug = this.$route.params.slug;
        if(this.slug)
            this.validLink();
    },
    methods: {
        recuperar: function(){
            if(!this.email || this.email.trim() == ''){
                this.msg = {
                    type: 'ko',
                    text: 'Falta el email'
                }
                return ;
            }
            this.msg = null;

            axios
            .post(`${process.env.VUE_APP_URL}reset_password`, {
                email: this.email
            })
            .then(response => {
                let rs = response.data;
                if(rs.state == 'OK'){
                    this.msg = {
                        type: 'ok',
                        text: 'Enviado correctamente, revisa tu email para continuar'
                    }
                }
                else{
                    this.msg = {
                        type: 'ko',
                        text: rs.msg
                    }
                }
            })
            .catch()
        },
        validLink: function(){
            axios
            .post(`${process.env.VUE_APP_URL}reset_password_c`,{
                token: this.slug
            }).then(response => {
                let rs = response.data;
                if(rs.state != 'OK'){
                    this.$router.push("/login");
                }
            })
            .catch(error => {
                this.$router.push("/login");
            })
        },
        changePassword: function(){
            if(!this.p1 || !this.p2){
                return;
            }
            if(this.p1 !== this.p2){
                this.message_password = "Las contraseñas no coinciden";
                this.message_password_type = false;
                this.clearPasswordMessage();
                return;
            }
            axios
            .post(`${process.env.VUE_APP_URL}reset_password_r`,{
                password: this.p1,
                token: this.slug
            })
            .then(response => {
                let rs = response.data;
                if(rs.state == 'OK'){
                    this.msg = {type: 'ok'};
                }
                else{
                    this.msg = {type: 'ok', text: "Hubo un error al cambiar la contraseña"};
                }
            })
            .catch(error => {
                this.msg = {type: 'ko', text: "Hubo un error al cambiar la contraseña"}
            })
        }
    }
}
</script>